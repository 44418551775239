
import { NavLink } from "react-router-dom";
// import Layout from "../components/layout";
// import Logo from "../assets/icons/africa.png";
import Logo from "../assets/images/logo.png";


const Error = () => {
  return (
    // <Layout>

    <div className="pt[20rem] flex items-center h-screen justify-center w-full bg-lemonGreen bg-opacity-20 ">
      <div className="flex-col justify-center text-center px-4">
        <div className="flex justify-center">
          <img
            src={Logo}
            alt="errorPage"
            className="w-auto h-[50px] lg:h-[100px] 2xl:h-[150px] "
          />
        </div>
        <h1 className="text-9xl font-bold font-roboto">
          4<span className="text-green">0</span>4
        </h1>
        <h1 className="font-raleway font-medium text-lg">
          This page does not exist
        </h1>
        <span>
          If this problem persists, contact
          <span className="text-lemonGreen inline-block px-1">Afri-Foods'</span>
          Engineers.
        </span>
        <button className="hidden bg-green hover:bg-lemonGreen text-white font-semibold text-lg px-4 py-2 mt-3 rounded-md">
          <NavLink to="/">Go Home</NavLink>
        </button>
      </div>
    </div>
    
    // </Layout>
  );
};

export default Error;

